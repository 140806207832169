<template>
  <div class="index-container">
    <el-steps
      :active="active"
      :space="230"
      style="margin-left: 20px"
      process-status="process"
      finish-status="success"
    >
      <el-step title="受测人"></el-step>
      <!-- <el-step title="心血管风险筛查"></el-step>
      <el-step title="IKDC问卷"></el-step>
      <el-step title="VAS问卷"></el-step> -->
      <el-step title="完成预约"></el-step>
    </el-steps>
    <customerEditor
      v-show="active == 0"
      ref="customerEditor"
      :step="true"
      @next="customerNext"
    />
    <heartEditor
      v-show="active == 1"
      ref="heartEditor"
      :step="true"
      @next="heartNext"
      @prev="returnStep"
    />
    <ikdceditor
      v-show="active == 2"
      ref="ikdceditor"
      :step="true"
      @next="ikdcNext"
      style="width: 1000px"
      @prev="returnStep"
    ></ikdceditor>
    <vasEditor
      v-show="active == 3"
      ref="vasEditor"
      :step="true"
      @next="vasNext"
      style="width: 1000px"
      @prev="returnStep"
    ></vasEditor>
    <appoint
      v-show="active == 4"
      ref="appoint"
      @next="appointNext"
      @prev="returnStep"
    >
    </appoint>
  </div>
</template>
  <script>
import customerEditor from "./components/customerEditor.vue";
import heartEditor from "./components/heartEditor.vue";
import ikdceditor from "./components/ikdceditor.vue";
import vasEditor from "./components/vasEditor.vue";
import appoint from "./components/appoint.vue";
import { pcAppoint } from "@/api/check/check.js";
export default {
  components: { customerEditor, heartEditor, ikdceditor, vasEditor, appoint },
  data() {
    return {
      active: 0,
      member: "",
      checkObj: "",
      heart: "",
      ikdc: "",
      vas: [],
    };
  },
  methods: {
    customerNext(val) {
      this.member = val;
      this.active=4;
    },
    heartNext(val) {
      this.heart = val;
      this.active++;
    },
    ikdcNext(val) {
      this.ikdc = val;
      this.active++;
    },
    vasNext(val) {
      this.vas = val;
      this.active++;
    },
    returnStep(val) {
      this.active=0;
    },
    appointNext(val) {
      this.checkObj = val;
      this.checkObj.Member = { Key: this.member.Key };
      // this.heart.Member = { Key: this.member.Key };
      // this.ikdc.Member = { Key: this.member.Key };
      // this.vas.Member = { Key: this.member.Key };
      let param = {
        member: this.member,
        check: this.checkObj,
        // heart: this.heart,
        // ikdc: this.ikdc,
        // vas: this.vas,
      };
      this.$confirm("确认提交预约吗？", "提示", {}).then(() => {
        pcAppoint(param).then((res) => {
          if (res) {
            this.msg.successMsg("预约成功！");
            this.$router.push("/appoint/check/appoint");
          }
        });
      });
    },
  },
  mounted() {},
};
</script>
  <style lang="scss" scoped>
.index-container {
  .pagination {
    margin-top: 10px;
    text-align: right;
  }
  padding: 10px;
  .input {
    width: 150px;
  }
  .tabs-container {
    /deep/.el-tabs--border-card > .el-tabs__content {
      padding: 0;
      height: 1px;
    }
    .tab-content {
      margin-top: -2px;
      border: 1px solid #dcdfe6;
      border-top: 0;
      padding: 15px;
      background: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    }
  }
}
</style>