var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "el-steps",
        {
          staticStyle: { "margin-left": "20px" },
          attrs: {
            active: _vm.active,
            space: 230,
            "process-status": "process",
            "finish-status": "success"
          }
        },
        [
          _c("el-step", { attrs: { title: "受测人" } }),
          _c("el-step", { attrs: { title: "完成预约" } })
        ],
        1
      ),
      _c("customerEditor", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.active == 0,
            expression: "active == 0"
          }
        ],
        ref: "customerEditor",
        attrs: { step: true },
        on: { next: _vm.customerNext }
      }),
      _c("heartEditor", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.active == 1,
            expression: "active == 1"
          }
        ],
        ref: "heartEditor",
        attrs: { step: true },
        on: { next: _vm.heartNext, prev: _vm.returnStep }
      }),
      _c("ikdceditor", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.active == 2,
            expression: "active == 2"
          }
        ],
        ref: "ikdceditor",
        staticStyle: { width: "1000px" },
        attrs: { step: true },
        on: { next: _vm.ikdcNext, prev: _vm.returnStep }
      }),
      _c("vasEditor", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.active == 3,
            expression: "active == 3"
          }
        ],
        ref: "vasEditor",
        staticStyle: { width: "1000px" },
        attrs: { step: true },
        on: { next: _vm.vasNext, prev: _vm.returnStep }
      }),
      _c("appoint", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.active == 4,
            expression: "active == 4"
          }
        ],
        ref: "appoint",
        on: { next: _vm.appointNext, prev: _vm.returnStep }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }